import { PROMO_CODE_URL_KEY } from "Services/constants";

export const decode = (input: string) => input.replace(/&#(\d+);/g, (_, dec) => String.fromCharCode(dec));

export const getPromoCode = (
	programCodesWithLevels: string[],
	roleCode: string,
	dynamicSettings: DynamicSettings,
	culture: string
) => {
	const promoCodeFromUrl = getPromoCodeFromUrl();

	if (promoCodeFromUrl) return promoCodeFromUrl;

	let promoCode;

	if (dynamicSettings) {
		// DEVNOTE The sorting is because BancoEstado (BEC-...) is stronger than DC (DCB-...)
		promoCode = dynamicSettings.DynamicPromoCodeSettings.sort((a, b) =>
			a.ProgramCodeAndLevel > b.ProgramCodeAndLevel ? 1 : -1
		).find(
			(item) =>
				(!item.Culture || item.Culture.toLowerCase() === culture.toLowerCase()) &&
				(!item.RoleCode || item.RoleCode.toLowerCase() === roleCode?.toLowerCase()) &&
				(!item.ProgramCodeAndLevel ||
					!programCodesWithLevels ||
					programCodesWithLevels.includes(item.ProgramCodeAndLevel))
		)?.Code;

		if (promoCode) return promoCode;

		switch (culture) {
			case "es-CL":
				return dynamicSettings.DefaultPromotionCodeCl;
			case "es-PE":
				return dynamicSettings.DefaultPromotionCodePe;
			case "es-AR":
				return dynamicSettings.DefaultPromotionCodeAr;
			case "es-CO":
				return dynamicSettings.DefaultPromotionCodeCo;
			case "en-US":
				return dynamicSettings.DefaultPromotionCodeUs;
			case "pt-BR":
				return dynamicSettings.DefaultPromotionCodeBr;
			case "es-PY":
				return dynamicSettings.DefaultPromotionCodePy;
			case "es-UY":
				return dynamicSettings.DefaultPromotionCodeUy;
			case "es-EC":
				return dynamicSettings.DefaultPromotionCodeEc;
			case "es-ES":
				return dynamicSettings.DefaultPromotionCodeEs;
			case "en-GB":
				return dynamicSettings.DefaultPromotionCodeGb;
			default:
				return "";
		}
	}

	return "";
};

const getPromoCodeFromUrl = () => {
	const urlElems = window.location.href.split("?");

	if (urlElems.length < 2) return undefined;

	const queryElems = urlElems[1].split("&");

	for (const elem of queryElems) {
		const [key, value] = elem.split("=");

		if (key.toLowerCase() === PROMO_CODE_URL_KEY.toLowerCase()) return value;
	}

	return undefined;
};
