import { TestIdDictionary } from "Services/test-ids/TestIdDictionary";
import { commonDebug } from "../../bootstrap";
import { html, useRef } from "haunted";
import i18next from "i18next";
import { useState, useEffect } from "Shared/haunted/CustomHooks";
import { HauntedFunc } from "Shared/haunted/HooksHelpers";
import { emailRegex } from "Services/constants";
import CryptoJS from "crypto-js";
import { clearErrors, validate } from "Services/form-validation";
import { RetrieveBookingResponse } from "Shared/models/RetrieveBookingResponse";
import { ref } from "Components/directives/ref";
import { locale } from "Services/Globals";
import { PUB_SUBS } from "Services/pub-sub-service/PubSub";
import { unsafeHTML } from "lit-html/directives/unsafe-html";

export const useShadowDOM = false;
export const name = "ac-admin-modal";

const loaderContainerClassName = "dg-new-modal-loader";

export const observedAttributes: (keyof Attributes)[] = ["culture"];

export interface Attributes {
	culture: string;
}

export interface Props {
	culture: string;
}

export type AdminModalType = "none" | "admin" | "checkin" | "onlineChange";

const captchaContainerClass = "bb-captcha-container";

const AesEncryptionKey = "fxf9YIhJbd8u5jbrwt463ek4AH1mfpn5";
const AesEncryptionIV = "3!fjrHKJR37f3jYP";

export const Component: HauntedFunc<Props> = (host) => {
	const props: Props = {
		culture: host.culture,
	};

	const initCaptcha = () => {
		const grecaptchaInterval = window.setInterval(() => {
			const captchaContainer = document.body.querySelector(`.${captchaContainerClass} div`) as HTMLDivElement;
			if (captchaContainer && grecaptcha && grecaptcha.render) {
				setCaptchaId(
					grecaptcha.render(captchaContainer, {
						sitekey: window.JetSmart.Settings.GoogleReCaptchaSiteKey,
						size: "invisible",
						callback: () => onSubmit(),
					})
				);

				window.clearInterval(grecaptchaInterval);
				window.clearTimeout(grecaptchaTimeLimit);
			}
		}, 100);

		const grecaptchaTimeLimit = window.setTimeout(() => {
			window.clearInterval(grecaptchaInterval);
			setShowRecaptchaError(true);
		}, 3000);
	};

	const handleSubmit = (e: Event) => {
		e.preventDefault();
		e.stopPropagation();

		setShowBookingError(false);
		setShowFareLockError(false);
		setShowUnauthorizedError(false);
		setShowTooManyJourneysError(false);

		if (validate(form.current)) {
			grecaptcha.execute(captchaId);
		}
	};

	const getPostBody = () => {
		const elem = document.body.querySelector("[name=g-recaptcha-response]") as HTMLTextAreaElement;
		const grecaptchaResponse = elem.value;

		const bookingData = new FormData();

		const key = CryptoJS.enc.Utf8.parse(AesEncryptionKey);
		const iv = CryptoJS.enc.Utf8.parse(AesEncryptionIV);

		const aesPnr = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(pnr.current), key, {
			keySize: 128 / 8,
			iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7,
		});

		bookingData.append("booking", btoa(aesPnr.toString()));

		const aesIdentifier = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(emailOrLastName.current), key, {
			keySize: 128 / 8,
			iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7,
		});

		bookingData.append(
			emailRegex.test(emailOrLastName.current) ? "email" : "lastName",
			btoa(aesIdentifier.toString())
		);

		bookingData.append("culture", props.culture);
		bookingData.append("gRecaptchaResponse", grecaptchaResponse);

		return bookingData;
	};

	const onSubmit = async () => {
		const loader = window.newRtLoader(`.${loaderContainerClassName}`);
		loader.startLoader();

		const baseUrl = window.JetSmart.Settings.BookingUrl + "/api/Booking/RetrieveBookingFromCorp";
		const fetchOptions: RequestInit = {
			method: "POST",
			credentials: "omit",
			mode: "cors",
			body: getPostBody(),
		};

		const fetchParameters = {
			options: fetchOptions,
			url: baseUrl,
		};

		try {
			const response = await fetch(fetchParameters.url, fetchParameters.options);

			if (response.ok) {
				await handleServerResponse(response, loader);
			}
		} catch (e) {
			commonDebug.error(e);
			loader.stopLoader();
		}
	};

	const handleServerResponse = async (
		response: Response,
		loader: { startLoader: () => void; stopLoader: () => void; destroy: () => void }
	) => {
		const result = await response.text();
		const parsedResult = JSON.parse(atob(result)) as RetrieveBookingResponse;

		switch (parsedResult.code) {
			case 0: // All is good
				const queryCulture = `culture=${props.culture}`;
				const queryPnr = `rl=${pnr.current}`;
				const queryUser = emailRegex.test(emailOrLastName.current)
					? `em=${emailOrLastName.current}`
					: `ln=${emailOrLastName.current}`;
				const queryString = `?${queryPnr}&${queryUser}&${queryCulture}`;
				location.href = `${window.JetSmart.Settings.BookingUrl}/Booking/Retrieve${queryString}`;
				break;
			case 2: // Farelock expired
				setShowFareLockError(true);

				loader.stopLoader();
				grecaptcha.reset(captchaId);
				break;
			case 3: // CUG hold expired
				setShowFareLockError(true);

				loader.stopLoader();
				grecaptcha.reset(captchaId);
				break;
			case 4: // CUG3 opened by non CUG3
				// const [language, country] = props.culture.toLowerCase().split("-");
				// window.location.href = `/${country}/${language}/ResultMessage?${CUG3_REDIRECT_URL_PARTIAL}`;
				break;
			case 5: // Someone else's CUG booking
				setShowUnauthorizedError(true);

				loader.stopLoader();
				grecaptcha.reset(captchaId);
				break;
			case 6: // More than 2 journeys
				setShowTooManyJourneysError(true);

				loader.stopLoader();
				grecaptcha.reset(captchaId);
				break;
			case 7: // American Airlines booking
				setShowAmericanAirlinesError(true);

				loader.stopLoader();
				grecaptcha.reset(captchaId);
				break;
			default:
				// Generic Error
				setShowBookingError(true);

				loader.stopLoader();
				grecaptcha.reset(captchaId);
				break;
		}
	};

	const handleIsOnlineChangeClick = () => {
		setType("onlineChange");
	};

	const handlePnrInput = (e: KeyboardEvent) => {
		const target = e.target as HTMLInputElement;
		const value = target.value;
		const sanitizedValue = value
			.split("")
			.filter((char) => /[a-zA-Z0-9]/.test(char))
			.join("")
			.substring(0, 6)
			.toUpperCase();
		pnr.current = sanitizedValue;
		target.value = sanitizedValue;
	};

	const form = useRef<HTMLFormElement>(undefined);

	// DEVNOTE We have to use these as captcha needs a callback, and so data stays current in that
	const emailOrLastName = useRef<string>(undefined);
	const pnr = useRef<string>(undefined);

	const [showBookingError, setShowBookingError] = useState<boolean>(false);
	const [showFareLockError, setShowFareLockError] = useState<boolean>(false);
	const [showUnauthorizedError, setShowUnauthorizedError] = useState<boolean>(false);
	const [showRecaptchaError, setShowRecaptchaError] = useState<boolean>(false);
	const [showTooManyJourneysError, setShowTooManyJourneysError] = useState<boolean>(false);
	const [showAmericanAirlinesError, setShowAmericanAirlinesError] = useState<boolean>(false);
	const [captchaId, setCaptchaId] = useState<any>(undefined);
	const [type, setType] = useState<AdminModalType>("none");

	useEffect(() => {
		if (type !== "none" && captchaId === undefined) {
			initCaptcha();
		}

		if (type === "none") {
			setCaptchaId(undefined);
		}

		setShowBookingError(false);
		setShowFareLockError(false);
		setShowUnauthorizedError(false);
		setShowTooManyJourneysError(false);
		pnr.current = "";
		emailOrLastName.current = "";
		clearErrors(form.current);
	}, [type]);

	useEffect(() => {
		const sub = PUB_SUBS.AdminModalOpened.subscribe((e) => setType(e.modalType));
		return () => sub.unsubscribe();
	}, []);

	const recaptchaErrorTemplate = () =>
		showRecaptchaError
			? html`<div class="row">
					<div class="col-xs-1">
						<div class="captcha-load-error">${i18next.t("CaptchaLoadError")}</div>
					</div>
			  </div>`
			: "";

	const bookingErrorTemplate = () =>
		showBookingError
			? html`<div class="row">
					<div class="col-xs-1">
						<div class="dg-booking-error">${i18next.t("BookingExistenceError")}</div>
					</div>
			  </div>`
			: "";

	const farelockErrorTemplate = () =>
		showFareLockError
			? html` <div class="row">
					<div class="col-xs-1">
						<div class="dg-booking-error">${i18next.t("FareLockError")}</div>
					</div>
			  </div>`
			: "";

	const unauthorizedErrorTemplate = () =>
		showUnauthorizedError
			? html`<div class="row">
					<div class="col-xs-1">
						<div class="dg-booking-error">${i18next.t("UnauthorizedError")}</div>
					</div>
			  </div>`
			: "";

	const showTooManyJourneysErrorTemplate = () =>
		showTooManyJourneysError
			? html`<div class="row">
					<div class="col-xs-1">
						<div class="dg-booking-error">${i18next.t("TooManyJourneysError")}</div>
					</div>
			  </div>`
			: "";

	const showAmericanAirlinesErrorTemplate = () =>
		showAmericanAirlinesError
			? html`<div class="row">
					<div class="col-xs-1">
						<div class="dg-booking-error">
							${unsafeHTML(
								i18next.t(
									"Tu reserva fue comprada en American Airlines, por favor visita {{-linkStart}}www.aa.com{{-linkEnd}}.",
									{
										linkStart: "<a href='http://www.aa.com'>",
										linkEnd: "</a>",
									}
								)
							)}
						</div>
					</div>
			  </div>`
			: "";

	const errorsContainerTemplate = () => html`${recaptchaErrorTemplate()} ${bookingErrorTemplate()}
	${farelockErrorTemplate()} ${unauthorizedErrorTemplate()} ${showTooManyJourneysErrorTemplate()}
	${showAmericanAirlinesErrorTemplate()}`;

	const nameOrEmailTemplate = () => {
		const id = "email-or-name-input";

		return html`<div class="form-group dg-enhanced-input">
			<label for=${id}>${i18next.t("administer-modal-right-tooltip-email-or-name")}</label>
			<input
				id=${id}
				placeholder="${i18next.t("Ejemplo")}: Perez"
				data-test-id=${TestIdDictionary.AdminModal.UserName}
				required
				.value=${emailOrLastName.current}
				@input=${(e: KeyboardEvent) => (emailOrLastName.current = (e.target as HTMLInputElement).value)}
			/>
			<div class="checkin-tooltip">
				<div class="arrow-box">${i18next.t("administer-modal-right-info-01")}</div>
			</div>
		</div>`;
	};

	const pnrInputTemplate = () => {
		const id = "pnr-input";

		return html`<div class="form-group dg-enhanced-input">
			<label for=${id}>${i18next.t("administer-modal-right-tooltip-reservation-code")}</label>
			<input
				id=${id}
				data-test-id=${TestIdDictionary.AdminModal.Pnr}
				placeholder="${i18next.t("Ejemplo")}: SAB3HS"
				required
				.value=${pnr.current}
				@input=${handlePnrInput}
			/>
			<div class="checkin-tooltip">
				<div class="arrow-box">${i18next.t("administer-modal-right-info-02")}</div>
			</div>
		</div>`;
	};

	const submitButtonTemplate = () => html`<div class="row">
		<div class="col-xs-1 col-sm-1-2">
			<button
				type="submit"
				class="dg-rounded-primary-btn"
				data-test-id=${TestIdDictionary.AdminModal.SubmitButton}
			>
				${i18next.t("administer-modal-right-continue")}
			</button>
		</div>
	</div>`;

	const captchaTemplate = () =>
		html`
			<div class="row">
				<div class="col-xs-1">
					<div class=${captchaContainerClass}>
						<div></div>
					</div>
				</div>
			</div>
		`;

	const formTemplate = () =>
		html` <form ref=${ref(form)} class="dg-modal-form" data-test-id="modal-form" novalidate @submit=${handleSubmit}>
			${nameOrEmailTemplate()} ${pnrInputTemplate()} ${errorsContainerTemplate()} ${submitButtonTemplate()}
		</form>`;

	const bannerTemplate = () =>
		type === "checkin"
			? html`
					<div class="dg-new-modal-banner checkin"></div>
					<div class="dg-new-modal-info-bar">${i18next.t("check-in-modal-info-bar")}</div>
			  `
			: type === "admin" || type === "onlineChange"
			? html`
					<div class="dg-new-modal-banner admin"></div>
					<div class="dg-new-modal-info-bar">
						${type === "onlineChange"
							? html`<div>${i18next.t("administer-modal-info-bar-itinerary")}</div>`
							: html`<div>
									${i18next.t("administer-modal-info-bar-flight-change")}
									<a class="cursor-pointer" @click=${handleIsOnlineChangeClick}>
										${i18next.t("administer-modal-info-bar-link")}</a
									>
							  </div>`}
					</div>
			  `
			: "";

	const titleTemplate = () => {
		return type === "checkin"
			? html`
					<div class="dg-caption">
						<span>${i18next.t("check-in-modal-title-1")}<br />${i18next.t("check-in-modal-title-2")}</span>
						<span>${i18next.t("check-in-modal-title-3")}</span>
					</div>
			  `
			: type === "admin" || type === "onlineChange"
			? html`
					${type === "onlineChange"
						? html`<span class="dg-new-large-modal-title ${locale.toLowerCase() === "en-us" ? "wider" : ""}"
								>${i18next.t("administer-modal-onchange-title")}</span
						  >`
						: html`<span class="dg-new-large-modal-title ${locale.toLowerCase() === "en-us" ? "wider" : ""}"
								>${i18next.t("administer-modal-title")}</span
						  >`}
			  `
			: "";
	};

	return type !== "none"
		? html` <div class="dg-new-modal">
				<div class="dg-new-modal-content ${loaderContainerClassName}" data-test-id="modal-content">
					<div class="dg-new-modal-close" data-test-id="modal-close-button" @click=${() => setType("none")}>
						&times;
					</div>
					${bannerTemplate()}
					<div class="grid h-1/2 sm:grid-cols-12">
						<div class="m-auto sm:col-span-4">${titleTemplate()}</div>
						<div class="sm:col-span-8">${formTemplate()}</div>
					</div>
					${captchaTemplate()}
				</div>
		  </div>`
		: html``;
};
