export default function getShowFarePriceForCulture(showFarePrices: ShowFarePrices, culture: string) {
	if (!showFarePrices) {
		return false;
	}
	switch (culture) {
		case "es-CL":
			return showFarePrices.Cl;
		case "es-PE":
			return showFarePrices.Pe;
		case "es-AR":
			return showFarePrices.Ar;
		case "es-CO":
			return showFarePrices.Co;
		case "en-US":
			return showFarePrices.Us;
		case "pt-BR":
			return showFarePrices.Br;
		case "es-PY":
			return showFarePrices.Py;
		case "es-UY":
			return showFarePrices.Uy;
		case "es-EC":
			return showFarePrices.Ec;
		case "es-ES":
			return showFarePrices.Es;
		case "en-GB":
			return showFarePrices.Gb;
		default:
			break;
	}
	return false;
}
